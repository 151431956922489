
import { getUrlWithFormat } from "@/utils/formatImgUrl";

export default {
    name: 'CardContent',
    components: {
    ButtonPlay: () => import("@/components/buttons/ButtonPlay"),
    Link: () => import("@/components/elements/Link.vue"),
  },
  props: ["card", "une", "mobileUne", "verticalMobile", "serie_section", "type", "isObfuscated"],
  methods: {
    getUrlWithFormat,
  },
}
